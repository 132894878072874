export default function Imprint() {
  return (
    <div style={{ fontSize: '0.8rem' }}>
      Made with ❤️ in 36 hours by
      <a href="https://twitter.com/MeirBank"> @MeirBank </a>
      <a href="https://twitter.com/elfouly_sharif">@elfouly_sharif </a>
      <a href="https://twitter.com/NaimAshhab">@NaimAshhab </a>
      at Ethamsterdam
    </div>
  );
}
